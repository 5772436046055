import styled from '@emotion/styled';

export const NoResultsWrapper = styled.div`
  padding: 130px 24px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  p,
  div {
    text-align: center;
  }

  p {
    color: #000000;
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 5px;
  }
`;
