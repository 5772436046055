import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

import { Tile, Header, Title, TileContentWrapper, MarginCol } from '../../../../components/common/dashboard-styles';
import * as S from './BarChart.styled';

export default function BarChart({ config }: any) {
  const renderChart = () => {
    return config.data?.length > 0 && config.data?.some((item: any) => (item.id ? item[item.id] : true)) ? (
      <ResponsiveBar
        data={config.data}
        keys={config.keys}
        indexBy={config.indexBy}
        margin={{ top: 10, right: 10, bottom: 150, left: 10 }}
        padding={0.3}
        layout="horizontal"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: '< 18',
            },
            id: 'dots',
          },
          {
            match: {
              id: '29-35',
            },
            id: 'lines',
          },
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisLeft={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Quantity',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 150,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={true}
        motionConfig={{
          mass: 1,
          tension: 90,
          friction: 15,
        }}
      />
    ) : (
      <S.NoResultsWrapper>
        <p>No results</p>
        <div>Please try different search or filter criteria</div>
      </S.NoResultsWrapper>
    );
  };

  return (
    <MarginCol span={config.width}>
      <Tile>
        <Header align="middle" justify="space-between">
          <Title>{config.title}</Title>
        </Header>
        <TileContentWrapper>
          <div style={{ height: config.height * 100 }}>{renderChart()}</div>
        </TileContentWrapper>
      </Tile>
    </MarginCol>
  );
}
